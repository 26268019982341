<template>
	<div style="margin-top: 80px; margin-right: 95px; margin-left: 95px;">
		<div style="background: #FFFFFF; box-shadow: 0px 10px 10px rgba(226, 236, 249, 0.5); border-radius: 30px;">
		<div style="padding-top: 28px; margin-left: 38px;  margin-right: 38px; margin-bottom:60px" class="d-flex justify-content-between">
			<p class="title">Propostas</p>
			<div class="d-flex">
				<div style="margin-right:30px">
					<proposal-filters-vue v-model="filters"></proposal-filters-vue>
				</div>
				
				<router-link :to="{ name: 'novaProposta' }">
					<button class="btn-proposta"> 
						Nova proposta
					</button>
				</router-link>
				
			</div>
		</div>
		<div style="padding-bottom: 40px;">
			<table style="text-align: left;">
				<thead>
					<tr class="" style="border-bottom: 1px solid #EEEEEE;">
						<th scope="col" style="padding-bottom: 14px; text-align: left; padding-left: 38px;">Cliente</th>
						<th scope="col">Data</th>
						<th scope="col">Validade</th>
						<th scope="col">Valor</th>
						<th scope="col" width="20%"></th>
					</tr>
				</thead>
				<tbody style="">
					<tr v-for="(proposal, i) in proposals" :key="i" style="border-bottom: 1px solid #EEEEEE; padding-left: 38px !important">
						<td style="text-align: left; padding-left: 38px; text-transform: uppercas; padding-top: 16px; padding-bottom: 16px;border-bottom: 1px solid #EEEEEE; padding-left: 38px !important">
							{{ proposal.client.nome }}
						</td>
						<td style="">
							{{ proposal.created_at | format_date }}
						</td>
						<td style="">
							{{ proposal.expire_date | format_expire_date }}
						</td>
						<td style="">
							{{ proposal.proposal_value }}
						</td>

						<td style="" colspan="3">
							<span
								class="material-icons-outlined"
								style="
									color: #384364;
									cursor: pointer;
									font-size: 16px;
									margin-right: 5%;
								"
								@click="startProposal(proposal)"
								v-b-tooltip.hover
								title="Transformar proposta em pré-contrato"
							>
								play_arrow
							</span>

							<router-link
								:to="{
									name: 'editProposta',
									params: { id: proposal.id },
								}"
							>
								<span
									style="
										color: #384364;
										cursor: pointer;
										font-size: 16px;
										margin-right: 5%;
										padding-top: 6%;
									"
									class="material-icons-outlined"
								>
									edit
								</span>
							</router-link>

							<span
								class="material-icons-outlined"
								@click="deleteProposal(i)"
								style="
									color: #384364;
									cursor: pointer;
									font-size: 16px;
								"
							>
								delete
							</span>
						</td>
					</tr>
				</tbody>
			</table>
			<div class="d-flex justify-content-end" style="margin-top: 34px; margin-right:40px">
				<v-pagination
				class="justify-content-end"
				v-model="page"
				:length="1"
				></v-pagination>
			</div>
		</div>
		
		<b-modal
			id="deleteProposal"
			size="dm"
			title="Removendo proposta"
			style="color: rgb(161, 0, 12) !important"
			@ok="handleDeleteOk"
		>
			<div>Tem certeza que deseja remover esta proposta</div>
			<div v-if="proposal">
				<table>
					<tr>
						<td style="text-transform: uppercase">
							{{ proposal.client.nome }}
						</td>
						<td>{{ proposal.consultant }}</td>
						<td>{{ proposal.created_at | format_date }}</td>
						<td>{{ proposal.expire_date | format_expire_date }}</td>
						<td>{{ proposal.proposal_value }}</td>
					</tr>
				</table>
			</div>
		</b-modal>
	</div>
</div>
</template>

<style scoped lang="scss">
@import "~/public/styles/sass/index.scss";

.title {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 33px;
	/* identical to box height */
	letter-spacing: -0.01em;
	color: #000000;
}

.btn-proposta{
	background: #006992;
	border-radius: 10px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	/* identical to box height */

	text-align: center;
	letter-spacing: -0.01em;

	color: #FFFFFF;
}

th{
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	/* identical to box height */
	letter-spacing: -0.01em;
	color: #B5B7C0;
}

td{
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	/* identical to box height */

	letter-spacing: -0.01em;
	color: #292D32;
}
</style>

<script>
import ProposalsService from "../../services/ProposalsService/ProposalsService";
import ClientService from "../../services/ClientService/ClientService";
import { DateTime } from "luxon";
import ProposalFiltersVue from "../../components/Common/ProposalFilters.vue";

export default {
	name: "ProposalPage",
	components: {
		ProposalFiltersVue,
	},
	data() {
		return {
			proposals: [],
			ProposalsService: null,
			proposal: null,
			clients: {},
			client_services: new ClientService(),
			filters: {
				status: null,
				region: null,
				period: [],
			},
		};
	},
	mounted() {
		this.ProposalsService = new ProposalsService();

		this.reload();
	},
	methods: {
		async reload() {
			let params = { started: 0 };
			if (this.filters.region) {
				params.client__estado = this.filters.region;
			}
			if (this.filters.status) {
				let now = DateTime.now();
				switch (this.filters.status) {
					case 1:
						params.expire_date__gte = now.toISODate();
						break;
					case 2:
						params.expire_date__lte = now.toISODate();
						break;
					case 3:
						params.expire_date__lte = now
							.plus({ days: 7 })
							.toISODate();
						params.expire_date__gte = now.toISODate();
						break;
					default:
						break;
				}
			}
			if (this.filters.period.length > 0) {
				params.expire_date__gte = DateTime.fromMillis(
					this.filters.period[0]
				).toISODate();
				params.expire_date__lte = DateTime.fromMillis(
					this.filters.period[1]
				).toISODate();
			}
			this.proposals = await this.ProposalsService.list({
				params,
			}).then((resp) => resp.data.results);
			let clients_list = new Set(this.proposals.map((p) => p.client));
			this.client_services
				.detailed_list(Array.from(clients_list))
				.then((r) => {
					this.clients = r;
					for (
						let index = 0;
						index < this.proposals.length;
						index++
					) {
						const element = this.proposals[index];
						element.client = this.clients[element.client];
					}
				});
			console.log(this.proposals);
		},

		updateProposal(proposals) {
			this.proposals = proposals;
		},
		startProposal(proposal) {
			this.ProposalsService.update({ id: proposal.id, started: 1 }).then(
				() => {
					this.reload();
				}
			);
		},
		handleDeleteOk() {
			this.ProposalsService.destroy(this.proposal.id).then(() => {
				this.reload();
			});
		},
		deleteProposal(i) {
			this.proposal = this.proposals[i];
			this.$root.$emit("bv::show::modal", "deleteProposal", "#btnShow");
		},
	},
	filters: {
		format_date(date) {
			let o_date = DateTime.fromISO(date);
			return o_date.toFormat("dd/MM/y");
		},
		format_expire_date(date) {
			let o_date = DateTime.fromISO(date);
			let now = DateTime.now();
			return `${
				o_date.diff(now, ["days", "hours"]).toObject()["days"]
			} dias`;
		},
	},
	watch: {
		filters: {
			handler() {
				this.reload();
			},
			deep: true,
		},
	},
};
</script>
